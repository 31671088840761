.heading {
	margin-top: 4rem;
	margin-bottom: 8rem;
	color: var(--info-color);
}

.subtitle {
	flex-grow: 1;
}

.loading {
	position: relative;
}

.loading::before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	border-radius: var(--round);
	background-image: linear-gradient(
		to right,
		var(--item-muted-color),
		var(--item-color)
	);
}

@media (max-width: 992px) {
	.heading {
		margin-top: 0;
		margin-bottom: 4rem;
	}
}
