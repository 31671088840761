.line {
	width: 1px;
	border-radius: var(--round-sm);
	background-color: var(--border-color);
}

@media (max-width: 992px) {
	.line {
		width: 100%;
		height: 1px;
	}
}
