.picker {
	padding: 2rem;
	border-radius: var(--round);
	background-color: var(--item-muted-color);
	transition: box-shadow var(--time);
	position: relative;
}

.picker::placeholder {
	color: var(--text-muted-color);
}

.picker:hover,
.picker:focus {
	outline: none;
	box-shadow: 0 0 0 1rem var(--border-muted-color);
}

.input {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
}
